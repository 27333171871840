import {makeStyles} from '@material-ui/core/styles';

import {GoldCardHeader} from './GoldCardHeader';

const useStyles = makeStyles(theme => ({
  headerTabs: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  }
}));

export const GoldCardTabs = ({activeTabIndex, onClick, products, currency}) => {
  const classes = useStyles();
  return (
    <div className={classes.headerTabs}>
      {products.map((product, index) => {
        return (
          <GoldCardHeader
            useWithTabs
            activeTab={index === activeTabIndex}
            key={`${index}-${product.name}`}
            onClick={() => onClick(index)}
            product={product}
            currency={currency}
          >
            {product.name}
          </GoldCardHeader>
        );
      })}
    </div>
  );
};
