import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  goldCardRoot: {
    height: 'fit-content',
    position: 'relative',
    borderRadius: 10,
    overflow: 'hidden',
    width: '100%',
    maxWidth: 412,
    border: `1px solid ${theme.palette.colors.blue['800']}`,

    borderTop: `3px solid var(--Yellow-700, #FF9A02)`,
    borderRight: `1px solid var(--Yellow-700, #FF9A02)`,
    borderBottom: `3px solid var(--Yellow-700, #FF9A02)`,
    borderLeft: `1px solid var(--Yellow-700, #FF9A02)`
  },
  bgImage: {
    backgroundImage: 'url("/images/imagesNext/bg/goldCard.png")',
    opacity: 0.6,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundSize: '60%'
  }
}));

export const BuyGoldCard = ({children, background}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.goldCardRoot}
      style={{
        background,
        backgroundSize: 'auto'
      }}
    >
      <div className={classes.bgImage} />
      {children}
    </div>
  );
};

BuyGoldCard.propTypes = {
  product: PropTypes.object
};
