export const SafetyIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={88}
    height={88}
    viewBox='0 0 88 88'
    fill='none'
    {...props}
  >
    <rect
      width='88'
      height='88'
      x='.5'
      fill='currentColor'
      // fillOpacity='.05'
      rx='8'
    />
    <rect
      width='86'
      height='86'
      x='1.5'
      y='1'
      // stroke='currentColor'
      // strokeOpacity='.25'
      // strokeWidth='2'
      rx='7'
    />
    <path
      fill='#52D275'
      d='m59.731 25.512-11.693-4.381c-1.944-.727-5.131-.727-7.076 0l-11.693 4.381c-2.694 1.008-4.897 4.195-4.897 7.053v17.41c0 2.765 1.828 6.397 4.054 8.061l10.076 7.522c3.303 2.483 8.74 2.483 12.044 0l10.075-7.522c2.226-1.664 4.054-5.296 4.054-8.06v-17.41c-.047-2.86-2.25-6.046-4.944-7.054Zm-15.395 6.843a5.02 5.02 0 0 1 5.015 5.014 4.978 4.978 0 0 1-4.827 4.991h-.234c-2.813-.094-4.922-2.273-4.922-4.99a4.98 4.98 0 0 1 4.968-5.015Zm5.296 21.862c-1.43.937-3.28 1.429-5.132 1.429-1.85 0-3.725-.469-5.132-1.43-1.335-.89-2.062-2.108-2.085-3.444 0-1.312.75-2.577 2.085-3.468 2.836-1.874 7.452-1.874 10.287 0 1.336.89 2.086 2.11 2.086 3.445-.024 1.312-.774 2.577-2.11 3.468Z'
    />
  </svg>
);
