export const ChevronTinyIcon = props => (
  <svg
    width={10}
    height={4}
    viewBox='0 0 10 4'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='m1 .5 4 3 4-3'
    />
  </svg>
);
