import {Field, Form, Formik} from 'formik';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import React from 'react';
import clsx from 'clsx';

import useStorefrontData from 'src/hooks/useStorefrontData';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';
import {TextFieldFormAdapter} from 'src/componentsNext/formAdapters/TextFieldFormAdapter';

import {GoldAmountColumn} from './GoldAmountColumn';
import {GoldArrowsIcon} from './icons/GoldArrowsIcon';
import {useGoldCardData} from './useGoldCardData';
import {ButtonWithArrows} from './ButtonWithArrows';
import {GoldCardInfoRows} from './GoldCardInfoRows';

const useStyles = makeStyles(theme => ({
  cardContent: {
    // height: '100%',
    padding: theme.spacing(3),
    marginTop: -10,
    // backgroundColor: theme.palette.common.white,

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      marginTop: 0
    }
  },
  grid: {
    display: 'grid',
    gap: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(3)
    }
  },
  amountRow: {
    display: 'flex',
    gap: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(0.5)
    }
  },
  errorMessage: {
    ...extendedTypography.caption2MediumMobile,
    color: theme.palette.error.light,
    marginBottom: -theme.spacing(3),
    marginTop: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5),
      marginBottom: -theme.spacing(2.5)
    }
  },
  quickBuyButtons: {
    display: 'grid',
    gap: theme.spacing(1),
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr'
  },
  quickBuyButton: {
    height: 40,
    color: theme.palette.common.white,
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    backdropFilter: 'blur(5px)',
    border: `rgba(255, 255, 255, 0.06) !important`,

    '&:hover, &.selected': {
      border: `1px solid ${theme.palette.colors.yellow[600]} !important`,
      backgroundColor: 'rgba(255, 255, 255, 0.10) !important',
      transition: 'none'
    }
  },
  quickBuyButtonLabel: {
    ...extendedTypography.subtitleMediumDesktop
  },
  submitButton: {
    textTransform: 'uppercase'
  },
  arrowsIcon: {
    minWidth: 36,
    color: theme.palette.colors.yellow[900],

    [theme.breakpoints.down('sm')]: {
      minWidth: 24
    }
  }
}));

export const GoldCardContent = ({
  product,
  currency,
  hidden,
  quickBuyOptions
}) => {
  const classes = useStyles();
  // const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  // const {user} = useStorefrontData();
  const {
    formRef,
    initialFormValues,
    schema,
    productCurrency,
    onSubmit,
    onQuantityChange
  } = useGoldCardData({product, currency});

  if (hidden) {
    // console.log(id);
    return null;
  }

  return (
    <div className={classes.cardContent}>
      <Formik
        innerRef={formRef}
        initialValues={initialFormValues}
        validationSchema={schema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({values, setValues, errors}) => {
          // console.log(errors.quantity);
          return (
            <Form className={classes.grid}>
              <div>
                <div className={classes.amountRow}>
                  <GoldAmountColumn
                    label='M'
                    fieldProps={{
                      name: 'quantity',
                      onChange: e =>
                        onQuantityChange(
                          e.target.value,
                          'quantity',
                          values,
                          setValues
                        )
                    }}
                  />
                  <GoldArrowsIcon className={classes.arrowsIcon} />
                  <GoldAmountColumn
                    // useCurrencyDropdown
                    label={productCurrency}
                    fieldProps={{
                      name: 'price',
                      onChange: e =>
                        onQuantityChange(
                          e.target.value,
                          'price',
                          values,
                          setValues
                        )
                    }}
                  />
                </div>
                {/*made like this because of custom design when error message displays under the*/}
                {/*whole row not just below the input*/}
                {errors?.quantity && (
                  <Typography className={classes.errorMessage}>
                    {errors.quantity}
                  </Typography>
                )}
              </div>
              {/*<Field*/}
              {/*  fullWidth*/}
              {/*  name='email'*/}
              {/*  variant='outlined'*/}
              {/*  label='Your Email'*/}
              {/*  disabled={Boolean(user)}*/}
              {/*  component={TextFieldFormAdapter}*/}
              {/*  InputProps={{color: 'secondary'}}*/}
              {/*  InputLabelProps={{color: 'secondary'}}*/}
              {/*/>*/}

              <div className={classes.quickBuyButtons}>
                {quickBuyOptions.map((value, index) => (
                  <Button
                    key={index}
                    size='small'
                    variant='contained'
                    color='primary'
                    classes={{
                      root: clsx(
                        classes.quickBuyButton,
                        value === values.quantity && 'selected'
                      ),
                      label: classes.quickBuyButtonLabel
                    }}
                    onClick={() => {
                      onQuantityChange(value, 'quantity', values, setValues);
                    }}
                    style={{maxWidth: 100}}
                  >
                    {value}M
                  </Button>
                ))}
              </div>
              <Button
                fullWidth
                variant='contained'
                color='secondary'
                type='submit'
                className={classes.submitButton}
              >
                {/*Buy {product.gameVersion} gold*/}
                Buy now
              </Button>
              {/*<GoldCardInfoRows />*/}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
