import PropTypes from 'prop-types';
import {useCallback} from 'react';

import {NumberTextField} from 'src/componentsNext/inputs/NumberTextField';

// eslint-disable-next-line no-unused-vars,react/prop-types
export const NumberTextFieldFormAdapter = ({
  form,
  field,
  onChange,
  inputProps = {},
  InputProps = {},
  ...props
}) => {
  // const shouldShowError = meta?.touched && meta?.error;
  // const shouldShowError = form.errors[field.name];

  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange(e);
      }

      field.onChange(e);
    },
    [onChange, field]
  );

  return (
    <NumberTextField
      {...props}
      {...field}
      inputProps={{
        ...inputProps,
        onChange: handleChange
      }}
      InputProps={{...InputProps, ...props.InputProps}}
      // helperText={
      //   shouldShowError ? form.errors[field.name] /*|| meta.submitError*/ : ''
      // }
      error={form.errors[field.name]}
      // value={isNaN(field.value) || 0 ? null : field.value}
      value={field.value || field.value !== 0 ? parseFloat(field.value) : ''}
    />
  );
};

NumberTextFieldFormAdapter.propTypes = {
  field: PropTypes.object.isRequired
};
