export const GoldArrowsIcon = props => (
  <svg
    width={36}
    height={36}
    viewBox='0 0 36 36'
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m18.879 8.56-3.44-3.439L17.561 3l7.06 7.06-7.06 7.061L15.439 15l3.44-3.44H10v-3h8.879Zm.06 9.44 2.122 2.121-3.44 3.44H26.5v3h-8.879L21.061 30l-2.122 2.121-7.06-7.06L18.939 18Z'
      clipRule='evenodd'
    />
  </svg>
);
