export const HeaderElementIcon = props => (
  <svg
    width={68}
    height={6}
    viewBox='0 0 68 6'
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#E9B109'
      d='M.613 3 3.5.113 6.387 3 3.5 5.887.613 3ZM67.5 2.5a.5.5 0 0 1 0 1v-1Zm-64 0h64v1h-64v-1Z'
      opacity='.15'
    />
  </svg>
);
