import React from 'react';

export const CheckCircleIcon = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="m28.066 44.133 18.867-18.866-3.067-3-15.8 15.8-8-8-3 3 11 11.066ZM32 58.667c-3.645 0-7.09-.7-10.334-2.1-3.244-1.4-6.077-3.311-8.5-5.734-2.422-2.422-4.333-5.255-5.733-8.5-1.4-3.244-2.1-6.688-2.1-10.333 0-3.689.7-7.155 2.1-10.4 1.4-3.244 3.311-6.067 5.733-8.467 2.423-2.4 5.256-4.3 8.5-5.7 3.245-1.4 6.69-2.1 10.334-2.1 3.689 0 7.155.7 10.4 2.1 3.244 1.4 6.066 3.3 8.466 5.7 2.4 2.4 4.3 5.223 5.7 8.467 1.4 3.244 2.1 6.711 2.1 10.4 0 3.645-.7 7.089-2.1 10.333-1.4 3.245-3.3 6.078-5.7 8.5-2.4 2.423-5.222 4.334-8.466 5.734-3.245 1.4-6.711 2.1-10.4 2.1Z"
    />
  </svg>

);
