export const ClockIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      fill='#00B67A'
      d='M10 2.643a7.857 7.857 0 1 0 7.857 7.857A7.822 7.822 0 0 0 10 2.643ZM14.179 13a.75.75 0 0 1-.607.357.679.679 0 0 1-.358-.107l-3.928-2.357V6.929a.714.714 0 0 1 1.428 0v3.178l3.215 1.929a.679.679 0 0 1 .25.964Z'
    />
  </svg>
);
