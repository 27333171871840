import {makeStyles} from '@material-ui/core/styles';
import {useMemo} from 'react';

import {CurrencyEnum} from 'src/core/enums';
import {currenciesMap} from 'src/core/constants';
import useStorefrontData from 'src/hooks/useStorefrontData';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';
import {ChevronTinyIcon} from 'src/componentsNext/icons/arrows/ChevronTinyIcon';
import {Dropdown} from 'src/componentsNext/Dropdown';

export const useStyles = makeStyles(theme => ({
  popper: {
    minWidth: 142,
    width: 'fit-content',
    zIndex: theme.zIndex.appBar + 1
  },
  popperInline: {
    zIndex: theme.zIndex.modal + 1
  },
  paper: {
    marginTop: theme.spacing(1),
    padding: ['2px 0', '!important'],
    borderRadius: [4, '!important'],

    '& .optionLabel': {
      ...extendedTypography.body1MediumDesktop,
      lineHeight: 1.2
    }
  },
  rotateIcon: {
    transform: 'rotate(180deg)'
  },
  dropdown: {display: 'grid', justifyItems: 'center'}
}));

const Button = ({label, onClick, open, ...props}) => {
  const classes = useStyles();

  return (
    <div onClick={e => onClick(e)} {...props}>
      {label}
      <ChevronTinyIcon
        color='#fff'
        className={open ? classes.rotateIcon : ''}
      />
    </div>
  );
};

export const GoldCurrencyDropdown = ({children}) => {
  const classes = useStyles();
  const {currency, updateCurrency} = useStorefrontData();
  const currencies = [];
  Object.values(CurrencyEnum).forEach(value => {
    currencies.push(value);
  });

  const currenciesOptions = useMemo(() => {
    return currencies.map(currency => {
      return {
        label: currenciesMap[currency].label,
        value: currenciesMap[currency].value,
        icon: currenciesMap[currency].icon
      };
    });
  }, [currencies]);

  const handleChange = value => {
    updateCurrency(value);
  };

  return (
    <Dropdown
      DropdownComponent={Button}
      value={currency}
      options={currenciesOptions}
      onChange={handleChange}
      buttonLabel={children}
      PopperProps={{
        keepMounted: false,
        disablePortal: false,
        className: classes.popper
      }}
      PaperProps={{className: classes.paper}}
      dropdownComponentProps={{className: classes.dropdown}}
    />
  );
};
