export const DeliveryIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={88}
    height={88}
    viewBox='0 0 88 88'
    fill='none'
    {...props}
  >
    <rect
      width='88'
      height='88'
      x='.5'
      fill='currentColor'
      // fillOpacity='.05'
      rx='8'
    />
    <rect
      width='86'
      height='86'
      x='1.5'
      y='1'
      // stroke='currentColor'
      // strokeOpacity='.25'
      // strokeWidth='2'
      rx='7'
    />
    <path
      fill='#52D275'
      d='M41.235 30.93 31.78 42.313l-10.096 1.222 7.583-9.91 11.967-2.695ZM57.569 47.266l-11.383 9.453-1.222 10.096 9.91-7.58 2.695-11.97ZM66.315 33.067c1.62-4.619 1.127-8.855-.45-10.433-1.577-1.576-5.813-2.069-10.43-.45l10.88 10.883ZM65.454 35.135 53.365 23.047a21.04 21.04 0 0 0-6.035 4.427c-5.41 5.667-13.876 16.77-13.876 16.77l9.84 9.838.963.965s11.104-8.464 16.77-13.877a21.034 21.034 0 0 0 4.427-6.035Zm-18.125 6.037a4.843 4.843 0 1 1 6.849-6.849 4.843 4.843 0 0 1-6.849 6.849ZM38.7 56.049l-1.08-1.08c-1.234 4.618-7.284 6.22-7.284 6.22s2.314-2.828 2.444-4.758c.164-2.453-.772-1.543-1.93-1.03-1.266.563-3.28.58-3.28.58s.965-.836 1.993-2.894c.704-1.41 2.33-2.156 3.729-2.447l-.841-.84c-1.64-1.23-4.238.006-5.49 2.225-1.01 1.784-2.808 6.074-4.756 6.175 1.23 1.025 2.972 1.536 5.123.616 2.151-.923 2.665 0 .922 1.74-1.131 1.133-5.225 2.561-5.327 4.713 2.357-.511 7.137.286 10.553-1.332 3.892-1.843 5.839-5.429 5.224-7.888Z'
    />
  </svg>
);
