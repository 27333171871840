import {forwardRef} from 'react';
import Button from '@material-ui/core/Button';
import {darken, makeStyles} from '@material-ui/core/styles';

import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

import {GoldButtonArrowIcon} from './icons/GoldButtonArrowIcon';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: `linear-gradient(180deg, ${theme.palette.colors.yellow[800]} 50%, ${theme.palette.colors.yellow[900]} 50%)`,

    '&:hover': {
      background: `linear-gradient(180deg, ${darken(
        theme.palette.colors.yellow[800],
        0.05
      )} 50%, ${darken(theme.palette.colors.yellow[900], 0.05)} 50%)`
    },

    '&:before': {
      content: '""',
      width: 9,
      height: 15,
      position: 'absolute',
      left: 0,
      backgroundImage: 'url("/images/imagesNext/button/leftArrow.png")'
    },

    '&:after': {
      content: '""',
      width: 9,
      height: 15,
      position: 'absolute',
      right: 0,
      backgroundImage: 'url("/images/imagesNext/button/rightArrow.png")'
    }
  },
  label: {
    ...extendedTypography.h5SemiboldDesktop,
    textTransform: 'uppercase',
    lineHeight: 1.2,
    zIndex: 1
  }
  // leftIcon: {
  //   position: 'absolute',
  //   left: 0
  // },
  // rightIcon: {
  //   position: 'absolute',
  //   right: 0,
  //   transform: 'rotate(180deg)'
  // }
}));

export const ButtonWithArrows = forwardRef(({children, ...props}, ref) => {
  const classes = useStyles();

  return (
    <Button
      ref={ref}
      variant='contained'
      classes={{root: classes.root, label: classes.label}}
      {...props}
    >
      {/*<GoldButtonArrowIcon className={classes.leftIcon} />*/}
      {children}
      {/*<GoldButtonArrowIcon className={classes.rightIcon} />*/}
    </Button>
  );
});
