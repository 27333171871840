import {forwardRef, useCallback, useState} from 'react';

import {TextField} from 'src/componentsNext/inputs/TextField';

export const TextFieldFormAdapter = forwardRef(
  (
    {
      form,
      field,
      className,
      withCheckedIcon = false,
      helperText = '',
      fullWidth = false,
      input,
      meta,
      onChange,
      inputProps = {},
      InputLabelProps = {},
      ...props
    },
    ref
  ) => {
    // const shouldShowError = meta?.touched && meta?.error;
    const shouldShowError = form.errors[field.name];

    const handleChange = useCallback(
      e => {
        if (onChange) {
          onChange(e);
        }

        field.onChange(e);
      },
      [onChange, field]
    );

    return (
      <TextField
        id={field.name}
        {...field}
        // value can be overwritten by props
        value={field.value || ''}
        fullWidth={fullWidth}
        error={shouldShowError}
        helperText={
          shouldShowError
            ? form.errors[field.name] /*|| meta.submitError*/
            : helperText
        }
        withCheckedIcon={withCheckedIcon /*&& meta.valid*/ && input.value}
        inputProps={{
          ...inputProps,
          onChange: handleChange
        }}
        InputLabelProps={{
          ...InputLabelProps,
          classes: {
            focused: 'focused',
            shrink: 'shrink',
            marginDense: 'marginDense'
          }
        }}
        {...props}
        ref={ref}
      />
    );
  }
);
