import Typography from '@material-ui/core/Typography';
import {alpha, makeStyles} from '@material-ui/core/styles';

import {ClockIcon} from 'src/componentsNext/icons/info/ClockIcon';

import {GuaranteeIcon} from '../icons/info/GuaranteeIcon';
import {extendedTypography} from '../../themes/themeNext/extendedTypography';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gap: theme.spacing(2.5)
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '20px 1fr max-content',
    gap: theme.spacing(0.5),

    position: 'relative',

    '&:not(:last-child):after': {
      content: '""',
      width: '100%',
      height: 1,
      position: 'absolute',
      bottom: -12,
      backgroundColor: alpha(theme.palette.colors.blue[100], 0.5)
    }
  },
  text: {...extendedTypography.body2Medium},
  greenText: {
    ...extendedTypography.body2SemiboldDesktop,
    color: theme.palette.colors.green[800]
  }
}));

export const GoldCardInfoRows = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <ClockIcon />
        <Typography className={classes.text}>Average Delivery</Typography>
        <Typography className={classes.greenText}>4 Min</Typography>
      </div>
      <div className={classes.row}>
        <GuaranteeIcon />
        <Typography className={classes.text}>Guaranteed Delivery</Typography>
        <Typography className={classes.greenText}>20 Min</Typography>
      </div>
    </div>
  );
};
