import {useRouter} from 'next/router';
import {useApolloClient, useMutation} from '@apollo/client';
import {useMemo, useRef, useState} from 'react';
import {useSnackbar} from 'notistack';
import * as Yup from 'yup';

import {
  GoldCheckoutCreate
  // TrackQuickBuyWidgetMutation
} from 'src/gql/mutations/checkout';
import useStorefrontData from 'src/hooks/useStorefrontData';
import useBackdropLoading from 'src/hooks/useBackdropLoading';
import useCheckoutBoost from 'src/hooks/useCheckoutBoost';
import {
  // getRsnFromStorage,
  maybe,
  roundTo,
  saveCheckoutDataInStorage
  // saveRsnInStorage
} from 'src/core/utils';
import {GameVersionEnum, ProductTypeEnum} from 'src/core/enums';

const defaultValues = {
  [GameVersionEnum.OSRS]: 50,
  [GameVersionEnum.RS3]: 500
};

export const useGoldCardData = ({product, currency}) => {
  const router = useRouter();
  const {user} = useStorefrontData();
  const client = useApolloClient();
  const {showLoading, stopLoading} = useBackdropLoading();
  const [formValues, setFormValues] = useState(null);
  const formRef = useRef(null);
  const boostCheckout = useCheckoutBoost();
  const {enqueueSnackbar} = useSnackbar();

  // const [trackQuickBuyWidget] = useMutation(TrackQuickBuyWidgetMutation);

  const [goldCheckoutCreate] = useMutation(GoldCheckoutCreate, {
    onError: () => {
      stopLoading();
      enqueueSnackbar('Unexpected error, please contact our support.', {
        variant: 'error'
      });
    }
  });

  const sellPrice = useMemo(
    () =>
      product.sellPriceInCurrencies.find(
        priceItem => priceItem.currency === currency
      ),
    [currency, product.sellPriceInCurrencies]
  );

  const emailInput = maybe(() => formRef.current.values.email, null);
  const rsnInput = maybe(() => formRef.current.values.rsn, '');

  // this is used for reinit (when we pass new currency)
  const initialFormValues = formValues || {
    // rsn: user
    //   ? getRsnFromStorage(product.gameVersion, ProductTypeEnum.GOLD)
    //   : rsnInput || '',
    email: user ? user.email : emailInput || '',
    quantity: defaultValues[product.gameVersion],
    // price: roundTo.down(product.minSellQuantity * sellPrice.amount, 2),
    price: roundTo(defaultValues[product.gameVersion] * sellPrice.amount, 2),
    currency
  };

  const schema = Yup.object().shape({
    rsn: Yup.string()
      .max(12, 'Too Long!')
      .min(1, 'Too Short!')
      .matches(
        /^[a-zA-Z0-9-_ ]+$/,
        'Use only numbers, letters, spaces and hyphens(-)'
      )
      .transform((value /*, originalValue*/) => value.trim()),
    // .required('This field is required.'),
    email: Yup.string().email('Not valid email.'),
    // .required('This field is required.'),
    quantity: Yup.number()
      .min(
        product.minSellQuantity,
        `The minimum ${product.name} amount is ${product.minSellQuantity}M!`
      )
      .required('This field is required.'),
    price: Yup.number()
  });

  const syncValues = (targetValue, targetFieldName, values, setValues) => {
    const valuesToUpdate = {...values};

    if (isNaN(parseFloat(targetValue))) {
      return false;
    }

    let performUpdate = false;

    if (targetFieldName === 'quantity') {
      const price = roundTo(sellPrice.amount * targetValue, 2);
      valuesToUpdate.quantity = targetValue;
      valuesToUpdate.price = price;
      valuesToUpdate.currency = currency;
      performUpdate = price !== values.price;
    } else if (targetFieldName === 'price') {
      const quantity = roundTo(targetValue / sellPrice.amount, 2);
      valuesToUpdate.price = targetValue;
      valuesToUpdate.quantity = quantity;
      performUpdate = quantity !== values.quantity;
    } else {
      return;
    }

    if (performUpdate) {
      setValues(valuesToUpdate);
      setFormValues(valuesToUpdate);
    }
  };

  if (formValues && formValues.currency !== currency) {
    // update state values which are used for initial formik values while
    // we are using 'enableReinitialize' option
    syncValues(formValues.quantity, 'quantity', formValues, setFormValues);
  }

  const handleSubmit = async values_ => {
    const values = schema.cast(values_);
    showLoading();
    // await loadPaymentCountries();
    await boostCheckout();

    // if (isWidget) {
    //   const pageUrl = router.asPath.split('?')[0];
    //   await trackQuickBuyWidget({
    //     variables: {input: {page: pageUrl, email: values.email}}
    //   });
    // }

    const {data} = await goldCheckoutCreate({
      variables: {
        input: {
          product: product.id,
          currency,
          email: '',
          rsn: '',
          quantity: values.quantity
        }
      }
    });
    const {errors, checkout, created} = data.goldCheckoutCreate;

    if (errors.length > 0) {
      errors.map(error => enqueueSnackbar(error.message, {variant: 'error'}));
      stopLoading();
      return null;
    }

    if (created) {
      saveCheckoutDataInStorage(checkout, client);
      // saveRsnInStorage(values.rsn, product.gameVersion, ProductTypeEnum.GOLD);

      // redirect to checkout page
      router.push(
        `/checkout/summary/[productType]?token=${checkout.token}`,
        `/checkout/summary/${ProductTypeEnum.GOLD.toLowerCase()}?token=${
          checkout.token
        }`
      );
      return null;
    }

    showLoading();

    enqueueSnackbar(
      'Something went wrong, please try again or contact our support team.',
      {variant: 'error'}
    );
  };

  const handleQuantityChange = (value, name, values, setValues) => {
    syncValues(+value, name, values, setValues);
  };

  return {
    formRef,
    initialFormValues,
    schema,
    productCurrency: sellPrice.currency,
    productSellPrice: sellPrice.localized,
    productName: product.name,
    onSubmit: handleSubmit,
    onQuantityChange: handleQuantityChange
  };
};
