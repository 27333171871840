import {InputAdornment, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {extendedTypography} from 'src/themes/themeNext/extendedTypography';
import useStorefrontData from 'src/hooks/useStorefrontData';
import {currencyToSignMap} from 'src/views/CheckoutNext/utils';

import {NumberInput} from './NumberInput';

const useStyles = makeStyles(theme => ({
  textField: {width: '100%'},
  inputRoot: {
    paddingLeft: theme.spacing(1),
    borderRadius: 4,
    border: `1px solid ${theme.palette.colors.inputActiveBorder}`,
    backdropFilter: 'blur(2px)',
    backgroundColor: theme.palette.backgrounds.inputInDropdown,

    '&:hover': {
      borderColor: theme.palette.colors.inputActiveBorder,
      backgroundColor: theme.palette.backgrounds.inputInDropdown,
      transition: '0.5s all'
    },
    '&:focus, &.Mui-focused': {
      borderColor: theme.palette.colors.inputInDropdownBorderActive,
      backgroundColor: theme.palette.backgrounds.inputInDropdownActive,
      transition: '0.5s all'
    }
  },
  input: {
    height: 'unset',
    padding: theme.spacing(0.875, 2, 0.875, 0),
    textAlign: 'center',
    ...extendedTypography.body2Medium
  },
  underline: {
    '&:before, &:after': {content: '"" !important', display: 'none'}
  },
  startAdornment: {color: theme.palette.secondary.main}
}));

export const NumberTextField = ({...props}) => {
  const classes = useStyles();
  const {currency} = useStorefrontData();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <TextField
      {...props}
      classes={{root: classes.textField}}
      InputProps={{
        // eslint-disable-next-line react/prop-types
        inputProps: {
          className: classes.inputNumber,
          inputMode: 'decimal',
          ...props.inputProps
        },
        inputComponent: NumberInput,
        classes: {
          root: classes.inputRoot,
          input: classes.input,
          underline: classes.underline
        },
        ...props.InputProps
      }}
    />
  );
};
