export const GoldButtonArrowIcon = props => (
  <svg
    width={9}
    height={16}
    viewBox='0 0 9 16'
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    {...props}
  >
    <path fill='url(#GoldButtonArrowIcon)' d='M0 .5h9v15H0z' />
    <defs>
      <pattern
        id='GoldButtonArrowIcon'
        width='1'
        height='1'
        patternContentUnits='objectBoundingBox'
      >
        <use
          xlinkHref='#GoldButtonArrowIcon-b'
          transform='matrix(.11111 0 0 .06667 -.333 -.067)'
        />
      </pattern>
      <image
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAATCAYAAABLN4eXAAABmElEQVQ4EY3TTS8DQRjA8b9Vr6tq22pjE1Vt0CBNRYRK4+Us0biIiJuDiIu3eEldhB44OTmJg4OP4ivwGSSOTisZeXa2LK3qJLOZzM5vZ555noWfrQmQ3lCThQGg1esyrovlZQv5sXDhZD0F9AJBd64ONIBOIubx6MOWun97ugH6Pdj81zkFdQMngGLSVrvPD6eADXQA8r6qyWSI6cQ5KUu5MG5+2EdLm0AMaK8FNertumQvr4bvNzXMRN9HDleKXoxVUKPVsQsWB1VzaUGFr4sawmvhbGMBiAJt/h01ykTKZOMKO6jI92s00adIWi/EQmkvbkmF2zSCMjFTcVzQINKprKtlh1x8FxgCevxp0GgtV2Y+qUFHQHEw62AHj4ApIPE7BRq1BsruzYEySvMOlilgBpCjWV6lfFXJ9/GMJpV93HFIh/wg/BtIUBV0y1zCIRXeB6a9HWqCCgpiGHeMx7aBHCA1+CeoIBMYAJJe3VXF4N6z7yHBSeKk/mSxVLj8Il9B+9b+GEo1y0LBLY0A0fJV6XIp/+7wCbXwS/+pPayIAAAAAElFTkSuQmCC'
        id='GoldButtonArrowIcon-b'
        width='13'
        height='19'
      />
    </defs>
  </svg>
);
