export const CoinsIcon = props => (
  <svg
    width={40}
    height={40}
    viewBox='0 0 40 40'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    {...props}
  >
    <path
      fill='#52D275'
      d='M4.848 28.837v2.527c0 2.089 5.09 3.788 11.363 3.788 6.274 0 11.364-1.699 11.364-3.788v-2.527c-2.444 1.722-6.913 2.527-11.364 2.527-4.45 0-8.919-.805-11.363-2.527Zm18.939-16.413c6.274 0 11.364-1.698 11.364-3.787 0-2.09-5.09-3.788-11.364-3.788-6.274 0-11.364 1.698-11.364 3.788 0 2.089 5.09 3.787 11.364 3.787ZM4.847 22.628v3.054c0 2.09 5.09 3.788 11.364 3.788 6.274 0 11.364-1.699 11.364-3.788v-3.054c-2.444 2.012-6.919 3.054-11.364 3.054-4.444 0-8.919-1.042-11.363-3.054Zm24.622.651c3.391-.657 5.682-1.876 5.682-3.279v-2.527c-1.373.97-3.392 1.633-5.682 2.042v3.764Zm-13.258-8.96c-6.273 0-11.363 2.118-11.363 4.734s5.09 4.735 11.363 4.735c6.274 0 11.364-2.119 11.364-4.735s-5.09-4.735-11.364-4.735Zm12.98 3.332c3.55-.64 5.96-1.894 5.96-3.333v-2.527c-2.101 1.486-5.712 2.285-9.511 2.474 1.745.846 3.03 1.983 3.55 3.386Z'
    />
  </svg>
);
