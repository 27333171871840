import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useState} from 'react';
import clsx from 'clsx';

import {whyChooseItems} from 'src/views/HomePageNext/constants';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

import {SectionHeader} from 'src/views/GameItemsNext/components/SectionHeader';

const useStyles = makeStyles(theme => ({
  itemsGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2.5),
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, max-content)',
      gap: theme.spacing(1.5),
      rowGap: theme.spacing(2.5)
    }
  },
  item: {
    width: 255,
    height: 230,
    textAlign: 'center',

    padding: theme.spacing(4, 3),
    borderRadius: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5)
    },
    [theme.breakpoints.down('xs')]: {
      width: 158,
      height: 139,
      padding: theme.spacing(0.5),
      gap: theme.spacing(1.5)
    }
  },
  itemHovered: {
    background: 'rgba(22, 33, 55, 0.80)',
    border: `1px solid ${theme.palette.colors.blue[700]}`
  },
  text: {
    ...extendedTypography.h5Desktop,
    color: theme.palette.common.white,
    transition: 'all 0.3s',

    [theme.breakpoints.down('xs')]: {
      ...extendedTypography.body1SemiboldMobile
    }
  },
  subText: {
    ...extendedTypography.body1RegularDesktop,
    color: theme.palette.common.white,
    maxWidth: 198,
    opacity: 0,
    transition: 'all 0.3s',

    [theme.breakpoints.down('xs')]: {
      ...extendedTypography.body2RegularMobile,
      maxWidth: 158
    }
  },
  subTextHovered: {
    opacity: 1,
    transition: 'all 0.3s'
  },
  iconWrapper: {
    height: 88,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    transition: 'all 0.3s',

    [theme.breakpoints.down('xs')]: {
      height: 56
    }
  },
  iconWrapperHovered: {
    height: 88,
    transition: 'all 0.3s'
  },
  icon: {
    width: 88,
    height: 88,

    color: 'rgba(82, 210, 117, 0.05)',
    border: `2px solid rgba(82, 210, 117, 0.25)`,
    borderRadius: 8,
    transition: 'all 0.3s',

    [theme.breakpoints.down('xs')]: {
      width: 56,
      height: 56
    }
  },
  iconHovered: {
    height: 56,
    width: 56,
    color: 'rgba(82, 210, 117, 0.25)',
    border: `2px solid rgba(82, 210, 117, 0.50)`,
    borderRadius: 8,
    transition: 'all 0.3s'
  },
  textBox: {
    position: 'absolute',
    top: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    transition: 'all 0.3s',

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1.5),
      gap: theme.spacing(0.75)
    }
  },
  textBoxHovered: {
    top: 56,
    transition: 'all 0.3s'
  }
}));

export const WhyChooseSection = ({mt = 0, mb = 0}) => {
  const classes = useStyles();
  const isDownXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const [hoveredItem, setHoveredItem] = useState(null);

  function handleMouseEnter(hoveredItem) {
    setHoveredItem(hoveredItem);
  }

  function handleMouseLeave() {
    setHoveredItem(null);
  }

  return (
    <div style={{marginTop: mt, marginBottom: mb}}>
      <SectionHeader title='Why Choose Probemas?' mb={isDownXs ? 40 : 32} />
      <div className={classes.itemsGrid}>
        {whyChooseItems.map((item, index) => {
          const Icon = item.icon;
          const hovered = hoveredItem === index && !isDownXs;
          return (
            <div
              key={index}
              className={clsx(classes.item, hovered && classes.itemHovered)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className={clsx(
                  classes.iconWrapper,
                  hovered && classes.iconWrapperHovered
                )}
              >
                <Icon
                  className={clsx(classes.icon, hovered && classes.iconHovered)}
                />
                <div
                  className={clsx(
                    classes.textBox,
                    hovered && classes.textBoxHovered
                  )}
                >
                  <Typography className={classes.text}>{item.text}</Typography>
                  <Typography
                    className={clsx(
                      classes.subText,
                      (hovered || isDownXs) && classes.subTextHovered
                    )}
                  >
                    {item.subText}
                  </Typography>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
