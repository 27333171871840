import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

// import useStorefrontData from 'src/hooks/useStorefrontData';

export const NumberInput = ({inputRef, onChange, ...props}) => {
  // const {currency} = useStorefrontData();
  return (
    <NumberFormat
      {...props}
      // pattern='[0-9]*'
      // pattern='^[0-9]*\\.[0-9][0-9]$'
      getInputRef={inputRef}
      decimalScale={2}
      allowNegative={false}
      decimalSeparator='.'
      allowedDecimalSeparators={['.', ',']}
      // onValueChange={values => {
      //   if (values) {
      //     onChange({
      //       target: {
      //         value: values.value
      //       }
      //     });
      //   }
      // }}

      onValueChange={(values, sourceInfo) => {
        if (sourceInfo.event) {
          onChange(sourceInfo.event);
        }
      }}
    />
  );
};

NumberInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};
