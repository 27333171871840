import {PlaceOrderIcon} from 'src/componentsNext/icons/buyingSteps/PlaceOrderIcon';
import {CompletePaymentIcon} from 'src/componentsNext/icons/buyingSteps/CompletePaymentIcon';
import {MessageChatIcon} from 'src/componentsNext/icons/buyingSteps/MessageChatIcon';
import {CoinsIcon} from 'src/componentsNext/icons/buyingSteps/CoinsIcon';
import {LoyaltyRewardsIcon} from 'src/componentsNext/icons/whyChooseItems/LoyaltyRewardsIcon';
import {PeaceOfMindIcon} from 'src/componentsNext/icons/whyChooseItems/PeaceOfMindIcon';
import {SafetyIcon} from 'src/componentsNext/icons/whyChooseItems/SafetyIcon';
import {DeliveryIcon} from 'src/componentsNext/icons/whyChooseItems/DeliveryIcon';

export const buyingGoldSteps = [
  {icon: PlaceOrderIcon, text: 'Enter the Amount'},
  {icon: CompletePaymentIcon, text: 'Complete the Payment'},
  {icon: MessageChatIcon, text: 'Message Live Chat'},
  {icon: CoinsIcon, text: 'Pick up your Gold'}
];

export const whyChooseItems = [
  {
    icon: LoyaltyRewardsIcon,
    text: 'Loyalty Rewards',
    subText: 'Get Free Extras on Top of Your Purchase.'
  },
  {
    icon: PeaceOfMindIcon,
    text: 'Peace of Mind',
    subText: '78,415+ trust Probemas. Join the gang now!'
  },
  {
    icon: SafetyIcon,
    text: 'Maximum Safety',
    subText: 'Hassle-free transactions and safe in-game trades.'
  },
  {
    icon: DeliveryIcon,
    text: 'Fast Delivery',
    subText: '10 minutes is all it takes... Unless we do it in 5.'
  }
];
