export const GuaranteeIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <g clipPath='url(#a-GuaranteeIcon)'>
      <path
        fill='#00B67A'
        fillRule='evenodd'
        d='M9.41 2.272c.333-.124.695-.14 1.035-.046l.144.046 5.874 2.203c.612.23 1.03.79 1.084 1.433l.005.139v4.5a7.552 7.552 0 0 1-3.951 6.639l-.223.116-2.815 1.407c-.315.158-.68.175-1.006.053l-.12-.053-2.814-1.407a7.553 7.553 0 0 1-4.171-6.503l-.004-.252v-4.5c0-.653.378-1.242.96-1.518l.129-.054L9.41 2.272Zm3.47 5.4L9.023 11.53 7.54 10.046a.84.84 0 0 0-1.187 1.187l2.018 2.018c.36.36.945.36 1.305 0l4.393-4.393a.839.839 0 1 0-1.187-1.187Z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='a-GuaranteeIcon'>
        <path fill='#fff' d='M0 .5h20v20H0z' />
      </clipPath>
    </defs>
  </svg>
);
