import MuiTextField from '@material-ui/core/TextField';
import {forwardRef, useState} from 'react';
import {InputAdornment} from '@material-ui/core';
import {uniqueId} from 'lodash/fp';

import {CheckCircleIcon} from 'src/components/icons/CheckCircleIcon';

export const TextField = forwardRef(
  (
    {
      id,
      className,
      autoComplete = 'off',
      autoFocus = false,
      disabled = false,
      error = false,
      fullWidth = false,
      multiline = false,
      required = false,
      placeholder,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      FormHelperTextProps,
      InputLabelProps,
      InputProps,
      inputRef,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      SelectProps,
      helperText,
      helperTextRequired,
      withCheckedIcon,
      ...props
    },
    ref
  ) => {
    const [inputName] = useState(id || uniqueId('text-field-'));
    const helperTextOrDummy = helperTextRequired
      ? helperText || <span>&nbsp;</span>
      : helperText;
    const withHelperText = Boolean(helperText || helperTextRequired);

    return (
      <MuiTextField
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        multiline={multiline}
        inputRef={inputRef}
        ref={ref}
        id={`${inputName}-input`}
        aria-describedby={`${inputName}-helper-text`}
        aria-labelledby={`${inputName}-label`}
        autoComplete={autoComplete}
        error={error}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        helperText={withHelperText ? helperTextOrDummy : undefined}
        InputLabelProps={{
          ...InputLabelProps
          // ...(placeholder ? {'data-shrink': true} : {})
        }}
        InputProps={{
          endAdornment: withCheckedIcon && (
            <InputAdornment position='end'>
              <CheckCircleIcon width={20} height={20} />
            </InputAdornment>
          ),
          ...InputProps
        }}
        {...props}
      />
    );
  }
);
