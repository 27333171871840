export const LoyaltyRewardsIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={88}
    height={88}
    viewBox='0 0 88 88'
    fill='none'
    {...props}
  >
    <rect
      width='88'
      height='88'
      x='.5'
      fill='currentColor'
      // fillOpacity='.05'
      rx='8'
    />
    <rect
      width='86'
      height='86'
      x='1.5'
      y='1'
      // stroke='#52D275'
      // strokeOpacity='.25'
      // strokeWidth='2'
      rx='7'
    />
    <path
      fill='#52D275'
      d='M63.166 44v14a9.33 9.33 0 0 1-9.333 9.334H35.166A9.33 9.33 0 0 1 25.833 58V44a2.34 2.34 0 0 1 2.333-2.333h4.597A2.34 2.34 0 0 1 35.096 44v7.327a4.7 4.7 0 0 0 2.474 4.13 4.58 4.58 0 0 0 4.737-.21l2.216-1.447 2.053 1.377c1.424.957 3.244 1.073 4.76.257a4.649 4.649 0 0 0 2.497-4.13V44a2.34 2.34 0 0 1 2.333-2.333h4.667A2.34 2.34 0 0 1 63.166 44ZM66.666 32.334v2.333c0 2.567-1.236 4.667-4.666 4.667H27c-3.57 0-4.667-2.1-4.667-4.667v-2.333c0-2.567 1.097-4.667 4.667-4.667h35c3.43 0 4.666 2.1 4.666 4.667Z'
    />
    <path
      fill='#52D275'
      d='M43.657 27.67h-12.88a2.185 2.185 0 0 1 .07-3.033l3.314-3.314a2.24 2.24 0 0 1 3.15 0l6.346 6.347ZM58.2 27.67H45.32l6.347-6.347a2.24 2.24 0 0 1 3.15 0l3.313 3.314c.84.84.864 2.17.07 3.033ZM49.1 41.667A2.34 2.34 0 0 1 51.433 44v7.304c0 1.866-2.076 2.986-3.616 1.936l-2.1-1.4a2.331 2.331 0 0 0-2.567 0l-2.193 1.447c-1.54 1.027-3.594-.093-3.594-1.937V44a2.34 2.34 0 0 1 2.334-2.333H49.1Z'
    />
  </svg>
);
