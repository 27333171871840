export const PeaceOfMindIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={88}
    height={88}
    viewBox='0 0 88 88'
    fill='none'
    {...props}
  >
    <rect
      width='88'
      height='88'
      x='.5'
      fill='currentColor'
      // fillOpacity='.05'
      rx='8'
    />
    <rect
      width='86'
      height='86'
      x='1.5'
      y='1'
      // stroke='#52D275'
      // strokeOpacity='.25'
      // strokeWidth='2'
      rx='7'
    />
    <path
      fill='#52D275'
      d='M44.41 34.215a5.682 5.682 0 1 0 0-11.364 5.682 5.682 0 0 0 0 11.364ZM64.301 44.165l-5.296-1.105c-1.804-.378-3.585.45-4.527 1.92a19.488 19.488 0 0 0-2.944-1.212V40.62c.002-2.383-1.949-4.33-4.323-4.33h-5.415a4.338 4.338 0 0 0-4.328 4.33v3.166c-1.036.33-2.01.735-2.93 1.207-.942-1.477-2.725-2.31-4.532-1.932l-5.3 1.105c-2.326.486-3.836 2.794-3.35 5.129l1.33 6.354c.483 2.328 2.793 3.838 5.121 3.352l1.677-.351c.84 1.24 2 2.378 3.487 3.352 3.097 2.03 7.188 3.149 11.53 3.149 6.642 0 12.364-2.671 15-6.507l1.703.357c2.327.484 4.636-1.024 5.119-3.352l1.332-6.354c.484-2.335-1.026-4.643-3.354-5.13ZM44.498 62.98c-3.921 0-7.597-.988-10.338-2.794a10.898 10.898 0 0 1-2.343-2.028l1.284-.27c2.33-.484 3.84-2.791 3.354-5.122l-1.193-5.713a16.316 16.316 0 0 1 2.203-.982v1.043a4.34 4.34 0 0 0 4.33 4.326h5.414c2.376 0 4.323-1.95 4.323-4.326v-1.097c.791.282 1.534.606 2.224.973l-1.21 5.778c-.482 2.33 1.026 4.638 3.356 5.123l1.297.271c-2.418 2.863-7.202 4.818-12.7 4.818ZM63.149 41.684a5.682 5.682 0 1 0 0-11.364 5.682 5.682 0 0 0 0 11.364ZM27.018 41.565c3.074-.643 5.037-3.656 4.402-6.728a5.688 5.688 0 0 0-6.73-4.398 5.68 5.68 0 0 0-4.398 6.724 5.683 5.683 0 0 0 6.726 4.402Z'
    />
  </svg>
);
